$(document).ready(function () {
  
  const DRSaveButtonBaseClass = "btn btn-outline-secondary fs-5 w-50 p-3";  
  const DRSaveButton = document.getElementById("DRSaveButton");
  const form = document.getElementById("formDR");
  let statut = '';
  let profil = '';

  if (form) {

    document.getElementById("questionEntreprise").style.display = "none";
    document.getElementById("questionCandidat").style.display = "none";
    document.getElementById("departement").style.display = "none";
    document.getElementById("spinner").style.display = "none";
    document.getElementById("error").style.display = "none";
  
    document.getElementById("pays").addEventListener("change", function () {
      let pays = this.value;
      let departement = document.getElementById("departement");

      if (pays === "IC1_French, 1. Française") {
        departement.style.display = "block";
        departement.setAttribute("required", "required"); // Rendre obligatoire
      } else {
        departement.style.display = "none";
        departement.removeAttribute("required"); // Retirer l'obligation
      }
      
    });
  
    document.getElementById("statut").addEventListener("change", function () {
  
      statut = this.value;
  
      if (statut === "entreprise,entreprise") {
        document.getElementById("questionEntreprise").style.display = "block";
        document.getElementById("questionCandidat").style.display = "none";
        
        // GESTION REQUIRED
        document.getElementById("souhait").removeAttribute("required");
        document.getElementById("entreprise").setAttribute("required", "required");   
        
        $(form).addClass('gtag-trigger-entreprise');
        
      } else {
        document.getElementById("questionEntreprise").style.display = "none";
        document.getElementById("questionCandidat").style.display = "block";
        
        // GESTION REQUIRED
        document.getElementById("souhait").setAttribute("required", "required");   
        document.getElementById("entreprise").removeAttribute("required");
              
        $(form).addClass('gtag-trigger-etudiant');
      }
    });
  
  
    // Fonction pour hasher l'email avec SHA-256
  async function hashEmailSHA256(email) {
    // Convertir l'email en tableau de bytes (Uint8Array)
    const emailBytes = new TextEncoder().encode(email);
  
    // Calculer le hash SHA-256
    const hashBuffer = await crypto.subtle.digest('SHA-256', emailBytes);
  
    // Convertir le buffer en format hexadécimal
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  
    return hashHex;
  }
  
  
    function onSubmit(token) {
  
      // Récupérer le formulaire
      
      document.getElementById("error").style.display = "none";
      
      // Valider le formulaire
      if (form.checkValidity()) {
          // Cacher le formulaire
          form.style.display = "none";
          
          // Afficher le spinner
          document.getElementById("spinner").style.display = "block";
  
          /* Analytics */
          if (statut === "entreprise,entreprise") {
            profil = 'entreprise';
          }
          else{
            profil = 'candidat';
          }
  
          // Utilisation de la fonction pour hasher l'email
          let email = document.getElementById("email").value;
          hashEmailSHA256(email).then(emailLeadSHA256 => {
              // Ajout de l'email hashé à la data layer
              window.dataLayer.push({
                  "event": "contact",
                  "profil": profil,
                  "user_email": email,
                  "email_lead": emailLeadSHA256
              });
          });
  
          // Envoyer le formulaire
          form.submit();
          
      } else {
          document.getElementById("error").style.display = "block";
          window.location.href = "#top";
      }
  
    }

    window.onSubmit = onSubmit;  

  }
});


